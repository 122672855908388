export const HOME_PATH = '/';
export const LOGOUT_PATH = '/logout';
export const LOGIN_PATH = '/login';

export const ADMINISTRATION_PATH = '/administration';
export const ADMINISTRATION_RIGHTS_PATH = `${ADMINISTRATION_PATH}/right`;
export const ADMINISTRATION_RIGHTS_ADMIN_PATH = `${ADMINISTRATION_RIGHTS_PATH}/admin`;
export const ADMINISTRATION_RIGHTS_CENTRAL_PATH = `${ADMINISTRATION_RIGHTS_PATH}/central`;
export const ADMINISTRATION_RIGHTS_EDITOR_ROLES_PATH = `${ADMINISTRATION_RIGHTS_PATH}/editor/roles`;
export const ADMINISTRATION_RIGHTS_EDITOR_ACTIONS_PATH = `${ADMINISTRATION_RIGHTS_PATH}/editor/actions`;
export const ADMINISTRATION_RIGHTS_EDITOR_TEAM_ROLES_PATH = `${ADMINISTRATION_RIGHTS_PATH}/editor/teamRoles`;
export const ADMINISTRATION_RIGHTS_DEALER_PATH = `${ADMINISTRATION_RIGHTS_PATH}/dealer`;
export const ADMINISTRATION_RIGHTS_DEALER_ROLES_PATH = `${ADMINISTRATION_RIGHTS_PATH}/dealer/roles`;
export const ADMINISTRATION_RIGHTS_DEALER_RESPONSIBILITY_PATH = `${ADMINISTRATION_RIGHTS_PATH}/dealer/responsibility`;

export const ADMINISTRATION_RBOX_NOTIFICATION_PATH = `${ADMINISTRATION_PATH}/notification`

export const RBOX_PATH = '/ticket';

export const RBOX_NOTIFICATION_LIST_PATH = `${RBOX_PATH}/notifications`;
export const RBOX_TICKET_LIST_PATH = `${RBOX_PATH}/list`;
export const RBOX_TICKET_HISTORY_PATH = `${RBOX_PATH}/history`;
export const RBOX_TICKET_NEW_PATH = `${RBOX_PATH}/new`;
export const RBOX_EXPORT_PATH = `${RBOX_PATH}/export`;

export const RBOX_NOTIFICATION_PATH = `${RBOX_PATH}/notification`;

