import {
    RBOX_TICKET_STATUS_CLOSED,
    RBOX_TICKET_STATUS_FORWARDED_TO_DEALER,
    RBOX_TICKET_STATUS_FORWARDED_TO_EDITOR,
    RBOX_TICKET_STATUS_FORWARDED_TO_GLPI,
    RBOX_TICKET_STATUS_NEW,
    RBOX_TICKET_STATUS_NOT_TODO,
    RBOX_TICKET_STATUS_READ_BY_EDITOR,
    RBOX_TICKET_STATUS_SOLVED,
} from './form/statusConstants';
import { HIGH, LOW, MAJOR, MEDIUM, VERY_HIGH, VERY_LOW } from './form/levelConstants';
import { RBOX_TICKET_ACTION_ATTACHMENT_ADDED, RBOX_TICKET_ACTION_MESSAGE_ADDED } from './form/actionsConstants';
import {CENTRAL_TEAM} from './form/editorTeamConstants';

export const domains = 'RBOX';
export const modules = domains;

export const providers = {
    IDP: 'IDP',
    OKTA: 'OKTA'
}

export function getDealerRole() {
    return [
        "BASIC_DEALER"
    ]
}

export const dealerRoles = {
    BASIC_DEALER: 'BASIC_DEALER'
};

export const editorActionRoles = {
    CREATE_TICKET: 'CREATE_TICKET'
};

export const editorTeamRoles = {
    CENTRAL_TEAM: 'CENTRAL_TEAM',
};

export const languages = ['en', 'fr', 'de'];

export const locations = [
    'fr-FR',
    'en-GB',
    'de-DE'
];

export const ALL_COUNTRIES_VALUE = 'ALL';
export const SORT_ASC = 'asc';
export const SORT_DESC = 'desc';
export const SORT_NONE = '';


export const getAllFormStates = [
    RBOX_TICKET_STATUS_NOT_TODO,
    RBOX_TICKET_STATUS_NEW,
    RBOX_TICKET_STATUS_READ_BY_EDITOR,
    RBOX_TICKET_STATUS_FORWARDED_TO_EDITOR,
    RBOX_TICKET_STATUS_FORWARDED_TO_DEALER,
    RBOX_TICKET_STATUS_FORWARDED_TO_GLPI,
    RBOX_TICKET_STATUS_SOLVED,
    RBOX_TICKET_STATUS_CLOSED
];

export const getAllFormActions = [
    RBOX_TICKET_ACTION_ATTACHMENT_ADDED,
    RBOX_TICKET_ACTION_MESSAGE_ADDED
];

export const getPriority = [
    VERY_LOW,
    LOW,
    MEDIUM,
    HIGH,
    VERY_HIGH,
    MAJOR
];

export const getUrgency = [
    VERY_LOW,
    LOW,
    MEDIUM,
    HIGH,
    VERY_HIGH
];

export const getImpact = [
    VERY_LOW,
    LOW,
    MEDIUM,
    HIGH,
    VERY_HIGH
];

export const getEditorTeamRoles = [
    CENTRAL_TEAM,
];

export const getSegmentOptions = ['A', 'B', 'C', 'D', 'E', 'F', 'K', 'L', 'M', 'P', 'R', 'S', 'T', 'U', 'Z',];


